/* eslint eqeqeq: 0 */
import * as CryptoJS from 'crypto-js';
import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';

import instance from '../interceptor';
import { setErrorMsg } from '../store/slices/errorSlice';
import { setLoading } from '../store/slices/loadingSlice';
import {
  addPermissions,
  addUserPermissions,
  setLeavingUrl,
  setUserTimezone,
} from '../store/slices/userSlice';
import {
  AES_KEY,
  BUSINESS_GOALS,
  COMPANY,
  COMPANY_CREDITS,
  COMPANY_SETTINGS,
  COOKIE_DOMAIN_NAME,
  HEX,
  COMPANY_INTEGRATION,
  MEMBER_INDEX,
  ON_BOARDING_WAITING_PAYMENT,
  PERM_OBJ,
  PLANS_CHECKOUT,
  PLAN_PAYMENTS,
  PURCHASE_ORDER,
  PlatformAndDevice,
  RESEND_PURCHASE_ORDER,
  ROLES_PERMISSION,
  SALT,
  SPACE_LIST,
  CATALOG_INDEX,
} from './constant';

let iv = CryptoJS.enc.Hex.parse(HEX);
let validationRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const deriveKey = (aesKey, salt) => {
  const iterations = 20000;
  const keySize = 256 / 32;

  const key = CryptoJS.PBKDF2(aesKey, salt, {
    keySize: keySize,
    iterations: iterations,
    hasher: CryptoJS.algo.SHA256,
  });
  return key;
};

export const encryptPassword = (pass) => {
  const key = deriveKey(AES_KEY, SALT);
  const ciphertext = CryptoJS.AES.encrypt(pass, key, { iv: iv });
  return ciphertext.toString();
};

export const validateEmail = (email) => {
  return validationRegex.test(email);
};

export const navigateAccordingToRoleAndPaymentStatus = (
  navigate,
  company,
  user,
  selectedPlanId = null,
  dispatch = null
) => {
  if (company && Object.keys(company ?? {}).length) {
    let paymentStatus = company?.payment_status;
    let role = user?.['role']?.['name']; // ['Member', 'CIO', 'Finance Admin', 'IT Admin']

    // TODO: need to redirect to specific page based on payment_status and role of the user;
    if (role === 'CIO') {
      if (dispatch) {
        dispatch(setLeavingUrl(ON_BOARDING_WAITING_PAYMENT));
      }
      return navigate(ON_BOARDING_WAITING_PAYMENT);
    } else if (role === 'Finance Admin') {
      if (
        ['pending', 'completed', 'processing'].some(
          (val) => val === paymentStatus
        )
      ) {
        if (dispatch) {
          dispatch(setLeavingUrl(PLAN_PAYMENTS));
        }
        return navigate(PLAN_PAYMENTS);
      } else if (paymentStatus === 'checkout' || paymentStatus == null) {
        if (dispatch) {
          dispatch(setLeavingUrl(PLANS_CHECKOUT));
        }
        return navigate(PLANS_CHECKOUT, {
          state: { section: 'Billing Details' },
        });
      }
      return null;
    } else if (role === 'IT Admin') {
      if (dispatch) {
        dispatch(setLeavingUrl(ON_BOARDING_WAITING_PAYMENT));
      }
      return navigate(ON_BOARDING_WAITING_PAYMENT);
    } else {
      // for member
      if (dispatch) {
        dispatch(setLeavingUrl(ON_BOARDING_WAITING_PAYMENT));
      }
      return navigate(ON_BOARDING_WAITING_PAYMENT);
    }
  }
  return null;
};

export const setUserToStorage = (
  dispatch,
  action,
  res,
  detail = false,
  timezoneDispatch = true
) => {
  localStorage.setItem(process.env.REACT_APP_USER, JSON.stringify(res['user']));
  const userCookie = getCookie(process.env.REACT_APP_USER);
  let tokenCookie = userCookie ? JSON.parse(userCookie) : {};
  if (!detail) {
    setCookie(
      process.env.REACT_APP_USER,
      JSON.stringify({
        ...tokenCookie,
        authtoken: res['user']['login_token'],
        id: res['user']['studio_user_pid'],
      })
    );
  }
  dispatch(action(res['user']));
  dispatch(addPermissions(res?.['user']?.['all_permissions'] ?? null));
  dispatch(addUserPermissions(res?.['user']?.['user_permissions'] ?? null));
  if (timezoneDispatch) dispatch(setUserTimezone(res?.['user']?.['timezone']));
  if (res?.['user']?.['timezone'] == userSystemTimezone()) {
    deleteCookie('temp_timezone');
  }
};

export const setCompanyToStorage = (dispatch, action, res) => {
  localStorage.setItem('company', JSON.stringify(res['company']));
  dispatch(action(res['company']));
};

export const clearStorage = () => {
  localStorage.removeItem(process.env.REACT_APP_USER);
  localStorage.removeItem('company');
};

export const resendPurchaseOrderEmail = (
  financeEmail,
  purchaseOrder,
  companyId
) => {
  let id = purchaseOrder?.id;
  let payload = {
    purchase_order: {
      email: financeEmail,
    },
  };
  let url = `${COMPANY}/${companyId}/${PURCHASE_ORDER}/${id}/${RESEND_PURCHASE_ORDER}`;
  return instance.post(url, payload);
};

export const throwError = (error) => {
  return typeof error === 'string' || error instanceof String
    ? error
    : error?.message;
};

export const globalError = (dispatch, error) => {
  let axiosErr =
    error?.response?.data?.error ??
    error?.response?.data?.message ??
    error?.message;
  let err = axiosErr?.['base']?.[0] ?? axiosErr;
  dispatch(setErrorMsg(throwError(err)));
};

export const setLoader = (dispatch, value) => {
  dispatch(setLoading(value));
};

export const setCookie = (cname, cvalue, exdays = 60) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test'
  ) {
    document.cookie =
      cname +
      '=' +
      cvalue +
      ';' +
      expires +
      ';path=/; domain=' +
      COOKIE_DOMAIN_NAME;
  } else {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
};

export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(' ')) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (name) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test'
  ) {
    document.cookie =
      name +
      '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' +
      COOKIE_DOMAIN_NAME;
  } else {
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

export const getInitials = (fullName) => {
  if (!fullName) return;
  fullName = fullName.trim();
  let getNameInitials = fullName.split(' ');
  let lastName =
    getNameInitials[1] !== undefined && getNameInitials[1] !== ''
      ? getNameInitials[1][0]
      : '';
  return getNameInitials[0][0]?.toUpperCase();
};

export const getFullname = (first, last) => {
  return last &&
    !['null', 'undefined', null, undefined].some((val) => val === last)
    ? first + ' ' + last
    : first;
};

export const debounce = (func, timeout = 800) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const isAllowedTo = (
  title: string,
  mod: string,
  allPermissions = [],
  entity = { id: null, type: null },
  userPermissions = []
) => {
  let obj = PERM_OBJ?.[mod];
  let moduleName = obj?.module;
  let action = obj?.[title];

  return (
    allPermissions?.some(
      (item) => item.title == action && item.module == moduleName
    ) ||
    userPermissions?.some((permission) => {
      return (
        permission.title == action &&
        permission.module == moduleName &&
        permission.entity_id == entity?.id &&
        permission.entity_type == entity?.type
      );
    })
  );
};

export const groupBy = (xs, key, all = false) => {
  return xs?.reduce(function (rv, x) {
    if (all) {
      (rv[x[key]] = rv[x[key]] || []).push({
        id: x.id,
        title: x.title,
        checked: x.checked ?? false,
        ...x,
      });
    } else {
      (rv[x[key]] = rv[x[key]] || []).push({
        id: x.id,
        title: x.title,
        checked: x.checked ?? false,
      });
    }
    return rv;
  }, {});
};

export const validateCountryCode = (countryCode, phoneValue) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneWithCode = `+${countryCode}-${phoneValue}`;
    const phone = phoneUtil.parseAndKeepRawInput(phoneWithCode);
    let region = phoneUtil.getRegionCodeForNumber(phone);
    if (
      !(phoneUtil.isValidNumber(phone) && phoneUtil.isPossibleNumber(phone))
    ) {
      return false;
    }
    return { region };
  } catch (err: any) {
    return false;
  }
};

export const navigateToSpaceOrProject = (e, navigate) => {
  if (e?.target?.nodeName.toLowerCase() === 'strong' && e?.target?.dataset) {
    const attributes = e.target.dataset;
    let spaceId = attributes?.spaceId;
    let projectId = attributes?.projectId;
    let buildCardId = attributes?.buildCardId;
    if (spaceId && projectId && buildCardId) {
      navigate(
        `/company/spaces/${spaceId}/project/${projectId}/playground/${buildCardId}`
      );
    } else if (spaceId && projectId) {
      navigate(`/company/spaces/${spaceId}/project/${projectId}/overview`);
    } else if (spaceId) {
      navigate(`/company/spaces/${spaceId}/overview`);
    }
  }
};

export const compressImage = async (
  file,
  { quality = 1, type = file.type }
) => {
  const imageBitmap = await createImageBitmap(file);

  const canvas = document.createElement('canvas');
  canvas.width = imageBitmap.width;
  canvas.height = imageBitmap.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(imageBitmap, 0, 0);

  return await new Promise((resolve) => canvas.toBlob(resolve, type, quality));
};

export const getBcState = (state) => {
  if (state == 'completed' || state === 'running') {
    return 'green';
  } else if (state === 'draft') {
    return 'grey';
  } else if (state === 'pending_approval') {
    return 'yellow';
  } else if (state === 'ready_for_kickoff') {
    return 'blue';
  } else if (state === 'care') {
    return 'purple';
  } else if (state === 'delivered') {
    return 'green';
  } else {
    return 'purple';
  }
};

export const getCardLable = (state) => {
  return state?.replaceAll('_', ' ');
};

export const sortListAcc = (arr, key, sortDir) => {
  let temp = [...arr].sort((a, b) => {
    const x = a?.[key]?.toLowerCase();
    const y = b?.[key]?.toLowerCase();
    if (sortDir) {
      return x > y ? 1 : -1;
    } else {
      return x > y ? -1 : 1;
    }
  });
  return temp;
};

export const sortByDate = (arr, key, sortDir) => {
  let temp = [...arr].sort((a, b) => {
    const x = a?.[key];
    const y = b?.[key];
    if (sortDir?.toLowerCase() == 'latest') {
      return moment.utc(y).diff(moment.utc(x));
    } else if (sortDir?.toLowerCase() == 'oldest') {
      return moment.utc(x).diff(moment.utc(y));
    }
  });
  return temp;
};

export const sortProject = (e, arr) => {
  if (arr && arr?.length > 0) {
    let selectedVal = e.target.value;
    arr?.sort(function (a, b) {
      let textA = a?.name?.toUpperCase();
      let textB = b?.name?.toUpperCase();
      let timeA = a?.created_at;
      let timeB = b?.created_at;
      let cond = textA > textB ? 1 : 0;
      let countA = a?.build_cards?.length;
      let countB = b?.build_cards?.length;

      if (selectedVal == 'A-Z') {
        return textA < textB ? -1 : cond;
      } else if (selectedVal == 'Z-A') {
        return textA < textB ? cond : -1;
      } else if (selectedVal == 'Latest') {
        return moment.utc(timeB).diff(moment.utc(timeA));
      } else if (selectedVal == 'Oldest') {
        return moment.utc(timeA).diff(moment.utc(timeB));
      } else if (selectedVal == 'Number of BCs') {
        return countB - countA;
      }
    });
    return [...arr];
  }
};

export const makeDoubleDigit = (num) => {
  return parseFloat(Number(num)?.toFixed(2)) ?? 0;
};

export const getRandom = () => {
  let random_num = new Uint8Array(2048 / 8);
  let crypto = window.crypto;
  return String.fromCharCode(...crypto.getRandomValues(random_num));
};

export const getWidth = (num, width) => {
  let size = 0;
  if (num > 3) {
    size = width / 5;
  } else if (num == 3) {
    size = width / 4;
  } else {
    size = width / 3;
  }
  return size < 40 ? '36px' : `${size - 7}px`;
};

export const isEllipsisActive = (e) => {
  return e?.offsetWidth - 2 < (e?.firstChild?.scrollWidth || e.clientWidth);
};

export const getColorCodeAndClassForGoals = (status, archive) => {
  if (archive) {
    return { color: '#83889E', class: 'disabled' };
  } else if (status == 'on_track' && !archive) {
    return { color: '#69F0AE', class: 'on-track' };
  } else if (status == 'progressing' && !archive) {
    return { color: '#FFD740', class: 'progressing' };
  } else if (status == 'off_track' && !archive) {
    return { color: '#FF8A80', class: 'off-track' };
  } else if (status == 'accomplished' && !archive) {
    return { color: '#00C853', class: 'accomplished' };
  } else if (status == 'not_started' && !archive) {
    return { color: '#C0C3CE', class: 'not-started' };
  }
};

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const formatCurrency = (amount) => {
  if (!amount || isNaN(amount)) {
    return 'Invalid input';
  }

  const abbreviations = {
    K: 1000,
    M: 1000000,
  };

  let abbreviation;
  let formattedValue;

  if (amount < 1000) {
    abbreviation = '';
    formattedValue = amount.toFixed(2);
  } else if (amount < 1000000) {
    abbreviation = 'K';
    formattedValue = (amount / abbreviations.K).toFixed(1);
  } else {
    abbreviation = 'M';
    formattedValue = (amount / abbreviations.M).toFixed(1);
  }
  formattedValue = formattedValue.replace(/\.0+$/, '');
  return '$' + formattedValue + abbreviation;
};

export const snakeCaseToSentence = (text) => {
  const words = text?.split('_');

  if (words?.length > 0) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ');
  }
  return '';
};

export const redirectTo = (space, card, navigate) => {
  if (isBuildCardUnpaid(card)) {
    navigate(
      `/company/spaces/${space?.id}/playground/${card.id ?? card?.enterprise_id}`
    );
  } else {
    window.open(card?.home_url, '_self');
  }
};

export const toSnakeCase = (string) =>
  string?.replace(/\s+/g, '_').toLowerCase();

export const removeByAttr = function (arr, attr, value) {
  let i = arr.length;
  while (i--) {
    if (
      arr[i]?.hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export const formatDate = (
  date,
  utc = false,
  upperCase = false,
  timeFormat = 'DD MMM, YYYY'
) => {
  date = utc ? moment(date).utc() : moment(date);

  return upperCase
    ? date.format(timeFormat)?.toUpperCase()
    : date.format(timeFormat);
};

export const canViewPrivateSpace = (user, space) =>
  ['CIO', 'IT Admin'].includes(user?.role?.name) ||
  space?.owner?.id === user?.id ||
  Boolean(space?.members?.find((member) => member?.id === user?.id));

export const transNavigation = (transition, navigate, page) => {
  transition(() => {
    navigate(page);
  });
};

export const isPrivateSpaceOwner = (space, user) => {
  return (
    !space?.private || (space?.private && canViewPrivateSpace(user, space))
  );
};

export const replaceObjInArr = (
  sourceArr,
  targetArr,
  matchKey,
  pickKeys = ['icon']
) => {
  if (!sourceArr?.length || !targetArr?.length) return [];

  return sourceArr.map((srcItem) => {
    const targetItem = targetArr.find(
      (tarItem) => tarItem[matchKey] === srcItem[matchKey]
    );
    if (!targetItem) return srcItem;

    const pickedProperties = pickKeys.reduce((acc, key) => {
      acc[key] = targetItem[key];
      return acc;
    }, {});

    return { ...srcItem, ...pickedProperties };
  });
};

export const moveArrayItem = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const removeDuplicateFromArr = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const isBuildCardUnpaid = (card) =>
  ['draft', 'pending_approval'].includes(card?.state);

export const isUserCioOrSpaceOwner = (user, space) =>
  user?.id === space?.owner?.id || user?.role?.name === 'CIO';

export const userSystemTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const dayString = (date, utc = false) => {
  date = utc ? moment(date).utc() : moment(date);
  let currDate = utc ? moment().utc() : moment();

  if (date.isSame(currDate, 'date')) {
    return 'Today';
  } else if (date.isSame(currDate.add(1, 'days'), 'date')) {
    return 'Tomorrow';
  } else {
    return formatDate(date);
  }
};

export const findIcon = (platform) =>
  PlatformAndDevice.find((item) => item?.title === platform)?.icon;

export const isUserCioOrSpaceMember = (user, space) =>
  user?.role?.name === 'CIO'
    ? true
    : space?.owner?.id === user?.id
      ? true
      : space?.members?.some((member) => member?.id === user?.id);

export const convertDefaultIconToFile = async (
  iconUrl: string
): Promise<File> => {
  const response = await fetch(iconUrl);
  const blob = await response.blob();
  return new File([blob], 'default_icon.png', {
    type: 'image/png',
  });
};

export const navListItems = (compSel, permissions) => {
  return [
    {
      label: 'Spaces',
      icon: 'be-grid',
      isActive: false,
      route: SPACE_LIST,
      canActivate: isAllowedTo('update', 'space', permissions),
      disabled: false,
    },
    {
      label: 'Members',
      icon: 'be-user-group',
      isActive: false,
      route: MEMBER_INDEX,
      canActivate: isAllowedTo('update', 'user', permissions),
      disabled: false,
    },
    {
      label: 'Goals',
      icon: 'be-business-goals',
      isActive: false,
      route: BUSINESS_GOALS,
      canActivate: isAllowedTo('update', 'company_goal', permissions),
      disabled: false,
    },
    {
      label: 'Credits',
      icon: 'be-credit-card',
      isActive: false,
      route: COMPANY_CREDITS,
      canActivate:
        isAllowedTo('read', 'plan_credit_subscription', permissions) &&
        compSel?.active_plan_credit_subscription?.id,
      disabled: false,
    },
    {
      label: 'Integrations',
      icon: 'be-integrations',
      isActive: false,
      route: COMPANY_INTEGRATION,
      canActivate: isAllowedTo('read', 'integration', permissions),
      disabled: false,
    },
    {
      label: 'Catalogue',
      icon: 'be-vector-1',
      isActive: false,
      route: CATALOG_INDEX,
      canActivate: isAllowedTo('read', 'catalog', permissions),
      disabled: false,
    },
    {
      label: 'Automations',
      icon: 'be-automations',
      isActive: false,
      route: '/',
      canActivate: false,
      disabled: true,
    },
    {
      label: 'Roles & Permissions',
      icon: 'be-roles-permission',
      isActive: false,
      route: ROLES_PERMISSION,
      canActivate: isAllowedTo('update', 'role', permissions),
      disabled: false,
    },
    {
      label: 'Billing & Payment',
      icon: 'be-bill-payments',
      isActive: true,
      route: `${PLAN_PAYMENTS}/credits`,
      canActivate:
        isAllowedTo('read', 'payment', permissions) && Boolean(compSel?.plan),
      disabled: false,
    },
    {
      label: 'Account settings',
      icon: 'be-account-settings',
      isActive: false,
      route: COMPANY_SETTINGS,
      canActivate: isAllowedTo('update', 'company', permissions),
      disabled: false,
    },
  ];
};

export const pluralize = (
  count,
  noun,
  suffix = 's',
  strong = false,
  show = true
) => {
  let text = `${noun}${count != 1 ? suffix : ''}`;
  return show
    ? `${strong ? `<strong>${count}</strong>` : count} ${text}`
    : text;
};

export const toggleSection = (val, preference) => {
  return preference?.find((item) =>
    item?.title?.toLowerCase()?.includes(val?.toLowerCase())
  )?.active;
};

export const compareObjects = function (first, second) {
  if (first === second) return true;

  if (first === null || second === null) return false;

  if (typeof first !== 'object' || typeof second !== 'object') return false;

  let first_keys = Object.getOwnPropertyNames(first);

  let second_keys = Object.getOwnPropertyNames(second);

  if (first_keys.length !== second_keys.length) return false;

  for (let key of first_keys) {
    if (!Object.hasOwn(second, key)) return false;

    if (compareObjects(first[key], second[key]) === false) return false;
  }

  return true;
};

export const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
